import {
  GET_AUHTORIZATION_SCHEME_START,
  GET_AUHTORIZATION_SCHEME_ERROR,
  GET_AUHTORIZATION_SCHEME_SUCCESS,
  GET_GIFTCARD_START,
  GET_GIFTCARD_ERROR,
  GET_GIFTCARD_SUCCESS,
  SET_GIFTCARD_VERIFICATION,
  GET_PDF_BUNDLE_START,
  GET_PDF_BUNDLE_ERROR,
  GET_PDF_BUNDLE_SUCCESS,
  GIFTCARD_EXPORT_TYPE,
  productTitleKey,
  SALES_CHANNELS,
} from '../constants';

import getAuthorizationSchemeQuery from './queries/getAuthorizationScheme.graphql';
import exportGiftcardQuery from './queries/exportGiftcard.graphql';
import exportGiftcardQueryForBundle from './queries/exportGiftcardForBundle.graphql';
import listBundledPdfsQuery from './queries/listBundledPdfs.graphql';
import productSinglePresentations from './queries/productSinglePresentations.graphql';
import getProductsMinimalQuery from './queries/getProductsMinimal.graphql';
import { getFromState } from '../helpers';
import { setVerifyOwnerToken } from '../helpers/userStorage';

export const PRODUCT_PRESENTATION_KEYS = [
  'productTitle',
  'productLogo',
  'productLogoSquare',
  'productShortDescription',
  'productCampaign',
  'productSlug',
];

export function getGiftcardAuthorizationScheme({ exportType, id }) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_AUHTORIZATION_SCHEME_START,
      payload: {
        loading: true,
      },
    });

    try {
      const {
        data: {
          orders: { getAuthorizationScheme },
        },
      } = await client.query({
        query: getAuthorizationSchemeQuery,
        variables: {
          input: {
            exportType,
            id,
          },
        },
        context: {
          fetchPolicy: 'cache-first',
        },
      });

      dispatch({
        type: GET_AUHTORIZATION_SCHEME_SUCCESS,
        payload: {
          ...getAuthorizationScheme,
          exportType,
          token: id,
          loading: false,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_AUHTORIZATION_SCHEME_ERROR,
        payload: {
          errors: [{ ...error }],
          loading: false,
        },
      });
    }
    return getFromState(getState(), 'giftcard');
  };
}

export function getGiftcardBase64({
  id,
  exportType,
  verification,
  productId,
  page,
  salesChannelId = SALES_CHANNELS.B2B,
}) {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale },
    } = getState();
    dispatch({
      type: GET_GIFTCARD_START,
      payload: {
        loading: true,
      },
    });

    try {
      const {
        data: {
          orders: { exportVoucher },
        },
        errors,
      } = await client.query({
        query:
          page === GIFTCARD_EXPORT_TYPE.bundle
            ? exportGiftcardQueryForBundle
            : exportGiftcardQuery,
        variables: {
          input: {
            id,
            exportType,
            productId,
            ...verification,
          },
        },
      });

      let productTitle = null;
      let productSingle = null;
      if (exportVoucher?.productId) {
        const responseB2B = await client.query({
          query: productSinglePresentations,
          variables: {
            input: {
              id: exportVoucher.productId,
              salesChannelId,
              expectedLanguage: locale,
            },
          },
          context: {
            service: 'cms',
          },
          skip: !exportVoucher.productId,
        });
        productSingle = responseB2B?.data?.productSingle;
        // if product is not found in B2B sales channel try to look in B2C
        // this is here because we cannot query productTitle with presentationsFilter, and productSingle requires salesChannel input
        if (
          responseB2B?.errors?.length > 0 &&
          responseB2B?.errors.some(e =>
            e.message.includes('cannot be found in sales channel'),
          )
        ) {
          const responseB2C = await client.query({
            query: productSinglePresentations,
            variables: {
              input: {
                id: exportVoucher.productId,
                salesChannelId: SALES_CHANNELS.B2C,
                expectedLanguage: locale,
              },
            },
            context: {
              service: 'cms',
            },
          });

          productSingle = responseB2C?.data?.productSingle;
        }
        productTitle = productSingle?.presentations?.find(
          p => p.presentationKey === productTitleKey,
        )?.textContent;
      }

      dispatch({
        type: GET_GIFTCARD_SUCCESS,
        payload: {
          id,
          productId,
          productTitle,
          ...exportVoucher,
          loading: false,
          exportType,
          errors,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_GIFTCARD_ERROR,
        error,
        payload: {
          error,
          loading: false,
          id,
          productId,
          productTitle: null,
          exportType,
        },
      });
    }
    return getFromState(getState(), 'giftcard');
  };
}

export function getBundle({ token, exportType, verification }) {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale },
    } = getState();

    dispatch({ type: GET_PDF_BUNDLE_START, payload: { loading: true } });

    try {
      const {
        data: {
          orders: {
            listBundledPdfs: { pdfsGroupedByProductId },
          },
        },
        errors,
      } = await client.query({
        query: listBundledPdfsQuery,
        variables: {
          input: {
            id: token,
            exportType,
            ...verification,
          },
        },
      });

      if (!pdfsGroupedByProductId.length) {
        dispatch({
          type: GET_PDF_BUNDLE_ERROR,
          payload: {
            errors: ['No pdfs in bundle'],
            loading: false,
          },
        });
      }

      const productIds = pdfsGroupedByProductId.map(p =>
        p.productId.toString(),
      );
      const { salesChannelId = '1' } = pdfsGroupedByProductId[0];

      const {
        data: { productsBySalesChannel },
      } = await client.query({
        query: getProductsMinimalQuery,
        variables: {
          input: {
            salesChannelId,
            productIds,
            expectedLanguage: locale,
            includePresentationKeys: PRODUCT_PRESENTATION_KEYS,
          },
        },
        context: {
          service: 'cms',
        },
      });

      dispatch({
        type: GET_PDF_BUNDLE_SUCCESS,
        payload: {
          pdfsGroupedByProductId,
          productsBySalesChannel,
          loading: false,
          errors,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_PDF_BUNDLE_ERROR,
        payload: {
          errors: [{ ...error }],
          loading: false,
        },
      });
    }
    return getFromState(getState(), 'giftcard');
  };
}

export const setVerification = ({ name, value, token, exportType, pin }) => {
  setVerifyOwnerToken({ token, exportType, pin });
  return {
    type: SET_GIFTCARD_VERIFICATION,
    payload: {
      name,
      value,
    },
  };
};
