import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { ldJson } from './ldJSON';

const getProductData = params => {
  const {
    product: {
      data: {
        logo = '',
        title = '',
        marupDescription: description = '',
        markupValue = '',
        markupMin = '',
        markupMax = '',
      } = {},
      formData: { giftcardValue, valueCurrency = '' } = {},
    } = {},
    url = '',
  } = params;

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: title,
    image: logo,
    url,
    description,
    offers: {
      '@type': 'Offer',
      priceCurrency: valueCurrency,
      price: giftcardValue?.toString() || markupValue?.toString(),
      lowPrice: markupMin?.toString(),
      highPrice: markupMax?.toString(),
      availability: 'https://schema.org/InStock',
      itemCondition: 'https://schema.org/NewCondition',
    },
  };
};

const ProductMarkup = ({ product, url }) => {
  return ldJson(getProductData({ product, url }));
};
const mapState = state => {
  const { stockInfo } = state.product;
  return {
    product: {
      ...state.product,
      productNotInStock: stockInfo?.every?.(
        ({ allSkusOutOfStock = true }) => !!allSkusOutOfStock,
      ),
    },
  };
};
ProductMarkup.propTypes = {
  product: PropTypes.shape(),
  url: PropTypes.string,
};

export default compose(connect(mapState))(ProductMarkup);
